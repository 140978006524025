import { StyledButton } from '@tryhackme/thm-ui-components';
import styled from 'styled-components';

export const StyledContent = styled.div`
  padding: 5rem 4rem 4.6rem;
  text-align: center;
  position: relative;
  h2,
  p {
    color: ${({ theme }) => theme.colors.white};
  }
  h2 {
    font-size: 4rem;
  }
  p span {
    color: ${({ theme }) => theme.colors.primary.main};
  }
  p {
    font-size: 2.4rem;
    font-weight: ${({ theme }) => theme.fonts.weight.bold};
    margin-bottom: 2.4rem;
    line-height: 3rem;
  }
`;

export const StyledWrapper = styled.div`
  position: relative;
  overflow: hidden;
  &:after {
    content: '';
    background: radial-gradient(50% 50% at 50% 50%, #719cf9 0%, rgba(21, 28, 43, 0) 100%);
    position: absolute;
    bottom: -11rem;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 20rem;
    z-index: 0;
    opacity: 0.8;
  }
`;
export const Styledtag = styled.span`
  color: ${({ theme }) => theme.colors.primary.main};
  font-weight: ${({ theme }) => theme.fonts.weight.bolder};
  text-transform: uppercase;
  margin: 2rem 0;
  font-size: 1.6rem;
  display: block;
`;

export const StyledfFireshipRedirectButton = styled(StyledButton)`
  position: relative;
  z-index: 5;
`;

export const StyledCountdown = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2.4rem;
`;
